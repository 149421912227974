import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from "formik";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Box,
  Grid,
  FormLabel,
  FormGroup,
  LinearProgress,
} from "@material-ui/core";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsPDF from "jspdf";
import PptxGenJS from "pptxgenjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Const from "../../helpers/const";
import { toast } from 'react-toastify';
import { useParams, useHistory, useLocation} from 'react-router-dom';
import { useSelector } from "react-redux";



const useStyles = makeStyles({
  // Form Card AI Component
  Container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formikWrapper: {
    marginLeft: "10%",
  },
  "& .Mui-selected": {
    textAlign: "left",
  },

  // Keywords Input styles
  chipInputContainer: {
    marginBottom: "10px",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
    minHeight: "36px",
  },
  chip: {
    margin: "2px",
  },
  input: {
    border: "none",
    outline: "none",
  },
  // Response Styles
  WrapperResponse: {
    display: "flex",
    textAlign: "left !important",
    alignItems: "center",
    flexDirection: "column",
  },
  ButtonGroup: {
    width: "90%",
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    // margin: theme.spacing(0.5),
    backgroundColor: "#2196F3", // Blue color
    color: "#FFFFFF", // White text
    borderRadius: 20,
    width: "100%",
  },
});

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: 10,
  p: 2,
};

// Initial Values Formik
const initialValues = {
  subject: "",
  topic: "",
  level: "",
  lessonPlan: "",
  standard: "",
  university: "",
  age: "13",
  skill: "",
  activity: [],
  lesson_plan_length: "60",
  keywords: [],
  lesson_aims: [],
  esl: false,
};

// Validation Schema Yup
const validationSchema = Yup.object({
  // topic: Yup.string().required("Topic is required"),
});

const universityLevelDescriptions = {
  Foundation: "Include introductory concepts and foundational knowledge.",
  Intermediate: "Expand on foundational knowledge with intermediate topics.",
  Proficient: "Focus on advanced topics and practical applications.",
  Advanced: "Incorporate research elements and in-depth analysis.",
  Professional:
    "Emphasize professional skills and advanced theoretical knowledge.",
  Expert: "Target expert-level understanding and specialized research.",
};

// FUNCTION Custom  Chip Input using Material UI (can be made into a seperate component)
const ChipInput = ({ label, value, onChange, onKeyDown }) => {
  const classes = useStyles();

  // chip delete function
  const handleDelete = (chipToDelete) => () => {
    onChange(value.filter((chip) => chip !== chipToDelete));
  };

  return (
    <div>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        className={classes.input}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.value.trim()) {
            e.preventDefault();
            onChange([...value, e.target.value.trim()]);
            e.target.value = "";
          }
        }}
      />
      <div className={classes.chipContainer}>
        {value.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDelete(chip)}
            className={classes.chip}
          />
        ))}
      </div>
    </div>
  );
};

const activities = [
  "Gap Fill",
  "Definition Match",
  "Reading",
  "Quiz",
  "Gist Questions",
  "Comprehension questions",
  "Writing",
  "Games",
  "Discussion/Debate",
  "Case Studies",
  "Real World Projects",
  "Role-Plays",
  "HomeWork",
];
const standardDescriptions = {
  "IB PYP": "Align activities with PYP objectives and learning outcomes.",
  "IB MYP": "Ensure alignment with MYP curriculum framework and criteria.",
  "IB DP": "Integrate DP subject guides and assessment criteria.",
  "IB CP": "Follow CP core and career-related studies guidelines.",
  "Cambridge IGCSE": "Incorporate IGCSE syllabus and assessment objectives.",
  "Cambridge A-Level": "Align with A-Level curriculum and examination requirements.",
  "Edexcel International GCSE": "Use Edexcel IGCSE syllabus and learning outcomes.",
  "Edexcel International A-Level": "Follow Edexcel A-Level curriculum guidelines.",
  "Edexcel International Advanced Level": "Integrate advanced level objectives and assessment.",
  "AP": "Ensure alignment with AP course framework and exam preparation.",
  "CEFR A1": "Design activities for basic user competence (A1).",
  "CEFR A2": "Focus on elementary user skills (A2).",
  "CEFR B1": "Develop activities for independent user level (B1).",
  "CEFR B2": "Ensure tasks support upper intermediate proficiency (B2).",
  "CEFR C1": "Aim for advanced user capabilities (C1).",
  "CEFR C2": "Design for proficient user fluency (C2).",
  "Reggio Emilia": "Integrate Reggio Emilia principles and child-centric learning.",
  "Montessori": "Incorporate Montessori materials and self-directed activity.",
};

const CourseLesson = () => {
  let content=""
  const { id, weekId, topicId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const authUser = useSelector((store) => store.auth.user);
  
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonPlanResponse, setLessonPlanResponse] = useState(content);
  const [pdfData, setPdfData] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const steps = ["Generate Course Plan", "Response"];
  const [openModal, setOpenModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [correction, setCorrection] = useState("");
  const [courseDetail, setCourseDetail]= useState([]);
  



  // FUNCTION Handle Cancel
  const handleCancel = () => {
    setActiveStep(0);
    setLessonPlanResponse("");
    setisLoading(false);
  };

  const getCourses = async () => {
    try {
      const response = await axios.get(Const.BASE_URL + "data/user/" + authUser.id);
      console.log("courses>>>", response)
      setCourseDetail(response?.data?.data?.filter(course => course.id == id)[0])
    } catch (error) {
      console.log("Error getting courses:", error);
    }
  }

  useEffect(() => {
    getCourses()
  }, [])

  const universityDescriptions = {
    Foundation: "Include introductory concepts and foundational knowledge.",
    Intermediate: "Expand on foundational knowledge with intermediate topics.",
    Proficient: "Focus on advanced topics and practical applications.",
    Advanced: "Incorporate research elements and in-depth analysis.",
    Professional: "Emphasize professional skills and advanced theoretical knowledge.",
    Expert: "Target expert-level understanding and specialized research.",
  };

  const standardDescriptions = {
    "IB PYP": "Align activities with PYP objectives and learning outcomes.",
    "IB MYP": "Ensure alignment with MYP curriculum framework and criteria.",
    "IB DP": "Integrate DP subject guides and assessment criteria.",
    "IB CP": "Follow CP core and career-related studies guidelines.",
    "Cambridge IGCSE": "Incorporate IGCSE syllabus and assessment objectives.",
    "Cambridge A-Level": "Align with A-Level curriculum and examination requirements.",
    "Edexcel International GCSE": "Use Edexcel IGCSE syllabus and learning outcomes.",
    "Edexcel International A-Level": "Follow Edexcel A-Level curriculum guidelines.",
    "Edexcel International Advanced Level": "Integrate advanced level objectives and assessment.",
    "AP": "Ensure alignment with AP course framework and exam preparation.",
    "CEFR A1": "Design activities for basic user competence (A1).",
    "CEFR A2": "Focus on elementary user skills (A2).",
    "CEFR B1": "Develop activities for independent user level (B1).",
    "CEFR B2": "Ensure tasks support upper intermediate proficiency (B2).",
    "CEFR C1": "Aim for advanced user capabilities (C1).",
    "CEFR C2": "Design for proficient user fluency (C2).",
    "Reggio Emilia": "Integrate Reggio Emilia principles and child-centric learning.",
    "Montessori": "Incorporate Montessori materials and self-directed activity.",
  };

  const extractSection = (text, header) => {
    const regex = new RegExp(`## \\*\\*${header}\\*\\*\\s*([\\s\\S]*?)(?=## \\*\\*|$)`);
    const match = text.match(regex);
    return match ? match[1].trim() : null;
  };

  
  // FUNCTION Generate Lesson PLan
  const handleSubmit = async (values) => {
    setisLoading(true);
    setProgressValue();
    console.log("value: ", values);
    const keywordsString = values.keywords.toString();
    const lessonAimsString = values.lesson_aims.toString();
    let activitiesString = values.activity.toString();

    const selectedActivities = activitiesString
      .split(",")
      .map((activity) => activity.trim());

    async function generateGapFillActivity(values, keywordsString, lessonAimsString) {
      return `1.	Create 10 unique gap-fill sentences based on the topic ${values.topic}.
  2.	Ensure all sentences are in the language associated with '${values.topic}'.
  3.	Randomize the order of sentences to maintain consistency with the lesson's linguistic focus.
  4.	Align with the selected international standard: ${courseDetail.curriculumStandard ? courseDetail.curriculumStandard : "N/A"}.
  5.	Use language appropriate for ESL learners, matching the academic level ${values.level || values.university || "N/A"} and age ${values.age}.`;
    }

    async function leasonSaveToBackend (botReply, text, values) {
      console.log("save function ", botReply, text, values);
      
      const topic = text.split('\n')[0].replace('#', '').replace('**', '').trim();
      const aim = extractSection(text, 'Aim');
      const introduction = extractSection(text, 'Introduction');
      const keywords = extractSection(text, 'Keywords');
      
      try {
        const topicName = location.state?.topicName;
        const response = await axios.post(
          Const.BASE_URL + `add/${id}/lessonplan`,
          {
            "lessonPlanId": `${id}${weekId}${topicId}`,
            "topic": topicName,
            "title": topic ?? "",
            "aim": aim ?? "",
            "introduction": introduction ?? "",
            "keywords": keywords ?? "",
            "content": botReply,
            "activities": values?.activity
          }
        );
        console.log('response -> ', response);
        
        if(response.status === 201){
          toast.success(response?.data?.message)
        }
      } catch (error) {
        console.error('Error in save lesson in db: ', error.message);
        
      }
    }

    async function generateDefinitionMatchActivity(values, keywordsString, lessonAimsString) {
      return `1.	Develop 10 unique definition matching sentences based on the topic ${values.topic}.
  2.	Ensure all sentences are in the language associated with '${values.topic}'.
  3.	Randomize the order of sentences.
  4.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  5.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel
        || "N/A"} and age ${courseDetail.ageGroup}.`;
    }

    async function generateQuizActivity(values, keywordsString, lessonAimsString) {
      return `1.	Create a quiz with 6 unique multiple-choice questions based on the topic ${values.topic}.
  2.	Ensure all questions are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel || "N/A"} and age ${courseDetail.ageGroup}`;
    }

    async function generateReadingActivity(values, keywordsString, lessonAimsString) {
      const wordCounts = {
        Beginner: 60,
        PreIntermediate: 100,
        Intermediate: 150,
        UpperIntermediate: 200,
        Advanced: 600,
      };
      const level = values.level in wordCounts ? values.level : "PreIntermediate"
      let adjustedWordCount = wordCounts[level]
      console.log("Before adjustedWordCount", adjustedWordCount)

      if (adjustedWordCount < 50) {
        adjustedWordCount = 50
      } else if (adjustedWordCount > 500) {
        adjustedWordCount = 500;
      }
      console.log("After adjustedWordCount", adjustedWordCount)

      const response = `1. Create a reading article paragraph on the topic ${values.topic}.
          2. Use the following keywords: ${keywordsString ? keywordsString : "generate appropriate keywords"}.
          3. Ensure it is suitable for ${courseDetail.educationalLevel || courseDetail.universityLevel || "N/A"} level and ${values.age} age.
          4. Word count: ${adjustedWordCount} words.
          5. Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
          6. Use language appropriate for ESL learners`;

      return response;
    }

    async function generateGistQuestionsActivity(values, keywordsString, lessonAimsString) {
      return `1.	Formulate 6 unique gist questions to assess learners' understanding of the article.
  2.	Ensure all questions are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel ||
        "N/A"} and age ${courseDetail.ageGroup}.`;
    }

    async function generateComprehensionQuestionsActivity(values, keywordsString, lessonAimsString) {
      // Function to generate comprehensive questions based on the topic
      function generateQuestions(topic, level, keywords, lessonAims, esl, age, standard) {
        const question = `What is the significance of ${topic}?`;
        return question;
      }

      const comprehensiveQuestions = [];
      // Generate 8 comprehensive questions
      for (let i = 0; i < 8; i++) {
        const question = generateQuestions(
          values.topic,
          values.level,
          keywordsString,
          lessonAimsString ? lessonAimsString : "generate appropriate aims",
          values.esl,
          values.age,
          values.standard ? `**Standard:** ${courseDetail.standard}` : ""
        );
        comprehensiveQuestions.push(question);
      }

      return `1.	Create 8 unique comprehension questions based on the topic ${values.topic}.
  2.	Ensure all questions are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel || "N/A"} and age ${courseDetail.ageGroup}.`;
    }

    async function generateWritingActivity(values, keywordsString, lessonAimsString) {
      return `1.	Create 2 writing activities tailored to the learners' level based on the topic ${values.topic}.
  2.	Ensure all activities are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
        courseDetail.universityLevel ||
        "N/A"} and age ${courseDetail.ageGroup}.`;
    }

    async function generateGamesActivity(values, keywordsString, lessonAimsString) {
      return `Creating 2 games with detailed instructions on how to play, including all necessary content based on the topic ${values.topic
        } for ${courseDetail.educationalLevel} level, ${keywordsString
          ? " following key words " + keywordsString
          : " following the generated keywords "
        } ${lessonAimsString
          ? " and following aim " + lessonAimsString
          : " and following the generated aim "
        } ${values.esl
          ? `Using language appropriate for ESL learners, and ensure it matches the academic level ${courseDetail.educationalLevel} and age  ${courseDetail.ageGroup} .\n`
          : ""
        }\n`;
    }

    async function generateDiscussionDebateActivity(values, keywordsString, lessonAimsString) {
      return `1.	Formulate 8 unique discussion/debate questions to extend learning beyond the lesson.
  2.	Ensure all questions are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
        courseDetail.universityLevel ||
        "N/A"} and age ${courseDetail.ageGroup}`;
    }

    async function generateCaseStudiesActivity(values, keywordsString, lessonAimsString) {
      const wordCounts = {
        Beginner: 60,
        PreIntermediate: 100,
        Intermediate: 150,
        UpperIntermediate: 200,
        Advanced: 600,
      };
      const level = values.level in wordCounts ? values.level : "PreIntermediate"
      let adjustedWordCount = wordCounts[level]
      console.log("Before adjustedWordCount>>>>>", adjustedWordCount)
      if (adjustedWordCount < 50) {
        adjustedWordCount = 50
      } else if (adjustedWordCount > 500) {
        adjustedWordCount = 500;
      }
      console.log("After adjustedWordCount", adjustedWordCount)

      return `1. Develop a case study of at least ${adjustedWordCount} words based on the topic ${values.topic}.
  2. Ensure the case study is in the language associated with '${values.topic}'.
  3. Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4. Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel || "N/A"} and age ${courseDetail.age}`;
    }


    async function generateRealWorldProjectsActivity(values, keywordsString, lessonAimsString) {
      return `1.Create a step-by-step guide for 2 real-world projects related to the topic ${values.topic}.
  2.	Ensure all instructions are in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
        courseDetail.universityLevel ||
        "N/A"} and age ${courseDetail.ageGroup}
  `;
    }
    async function generateRolePlaysActivity(values, keywordsString, lessonAimsString) {
      return `1.	Develop 4 unique role-play activities with 8 example sentences.
  2.	Ensure all role plays are in the language associated with '${values.topic}'.
  3.	Each activity must involve 2 participants or objects.
  4.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  5.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel || courseDetail.universityLevel || "N/A"} and age ${courseDetail.ageGroup}.
  
  Example:
  
  Role Play 1: Finding a Product
  •	Characters: Shopper (Student 1) and Store Assistant (Student 2)
  •	Shopper: "Excuse me, could you help me find the cereal aisle?"
  •	Store Assistant: "Sure, the cereal is in aisle 5, next to the breakfast foods."
  •	Shopper: "Thank you. Do you carry gluten-free options as well?"
  •	Store Assistant: "Yes, we have a variety of gluten-free cereals. They're clearly labeled on the lower shelf."`;
    }

    async function generateHomeworkActivity(values, keywordsString, lessonAimsString) {
      return `1.	Design a homework task to reinforce learning based on the topic ${values.topic}.
  2.	Ensure the task is in the language associated with '${values.topic}'.
  3.	Align with the selected international standard: ${courseDetail.standard ? courseDetail.standard : "N/A"}.
  4.	Use language appropriate for ESL learners, matching the academic level ${values.educationalLevel ||
        courseDetail.universityLevel ||
        "N/A"} and age ${courseDetail.ageGroup}`;
    }

    async function generateActivityQuery(activity, values, FirstresponseData, keywordsString, lessonAimsString, isLast, i) {
      let activityQuery = `As part of your learning experience, I will mandatorily show only the ${activity} name in bold style at the beginning, and add some space above ${activity} name. 
          - I will cover the skills of ${values.skill}.\n
          - I must not show any notes.
          - I must not add introduction of the topic.
          - I will show the activity in the same language that is used by ${values.topic}
          ${universityDescriptions[values.university]
          ? `- ${universityDescriptions[values.university]}\n`
          : ""
        }
          ${standardDescriptions[values.standard]
          ? `- ${standardDescriptions[values.standard]}\n`
          : ""
        }
          - ${values.esl
          ? `I will use language appropriate for ESL learners, and ensure it matches the academic level ${courseDetail.educationalLevel} and age ${courseDetail.ageGroup}.\n`
          : "I will make sure the language must be according to age and level, like for beginners easy and simple English and increase the level according to their age and level."
        }
      Formatting Guidelines
  •	Main Headers: Use #  .
  •	Subheaders: Use ##  .
  •	Bold: Use **bold** for all headings and subheadings.
  •	Lists: Use bullet points for lists and numbered lists where appropriate.
  •	Spacing: Ensure clear spacing between sections for readability.
  •	Notes: Do not add any notes or topic introductions.
  •	Add a seperate ----- at the end of response.
   
        - I must: `;

      // Add activity-specific details
      if (activity == "Gap Fill") {
        activityQuery += await generateGapFillActivity(values, keywordsString, lessonAimsString);
      } else if (activity === "Definition Match") {
        activityQuery += await generateDefinitionMatchActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Quiz") {
        activityQuery += await generateQuizActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Reading") {
        activityQuery += await generateReadingActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Gist Questions") {
        activityQuery += await generateGistQuestionsActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Comprehension Questions") {
        activityQuery += await generateComprehensionQuestionsActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Writing") {
        activityQuery += await generateWritingActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Games") {
        activityQuery += await generateGamesActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Discussion/Debate") {
        activityQuery += await generateDiscussionDebateActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Case Studies") {
        activityQuery += await generateCaseStudiesActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Real World Projects") {
        activityQuery += await generateRealWorldProjectsActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "Role-Plays") {
        activityQuery += await generateRolePlaysActivity(values, keywordsString, lessonAimsString);
      } else if (activity == "HomeWork") {
        activityQuery += await generateHomeworkActivity(values, keywordsString, lessonAimsString);
      }
      activityQuery += `
        \n\n`;
      return activityQuery;
    }

    async function generateFirstQuery(values) {
      let activityQuery =`As part of your learning experience, I will create a comprehensive lesson on ${courseDetail.courseTitle}  of ${courseDetail.topic}
        entirely in the language of the topic '${values.topic}'.
           This lesson will cover:
        - Clearly stating the 
  ${courseDetail.ageGroup ? `age ${courseDetail.ageGroup}, ` : ""} 
  ${courseDetail.standard ? `international standard (${courseDetail.standard}), ` : ""} 
  ${courseDetail.universityLevel ? `University (${courseDetail.universityLevel}), ` : ""} 
  ${courseDetail.educationalLevel ? `and level ${courseDetail.educationalLevel} ` : ""} 
  of our lesson at the beginning.
  - Including a clear aim to guide our learning.
        - Crafting an engaging introduction on the topic of ${values.topic} 
          suitable for the age group of ${courseDetail.ageGroup} and academic level ${courseDetail.educationalLevel}, mandatory including an example to illustrate the topic.
        - Generate 10 keywords related to the topic ${values.topic}.
        - **Note - The generated response must only contain keywords ,introduction , aim and in the beginning title ${values.topic
        }, age ${courseDetail.ageGroup} and level ${courseDetail.educationalLevel}.
        Formatting Guidelines
  •	Main Headers: Use #  .
  •	Subheaders: Use ##  .
  •	Bold: Use **bold** for all headings and subheadings.
  •	Lists: Use bullet points for lists and numbered lists where appropriate.
  •	Spacing: Ensure clear spacing between sections for readability.
  •	Notes: Do not add any notes or topic introductions.
  \n\n\n\n`;
      console.log(activityQuery);
      return activityQuery;
    }


    async function generateLastQuery(values, combinedResponses) {
      let activityQuery = `
  1. Provide answers to all questions and exercises (MCQs, matching activities, etc.) clearly labeled as "Answer Key" and make sure no questions are left unanswered from this but extract questions from this for answering, not creating new ones, just from this and do not add or write new questions please(${combinedResponses}),
  2. Ensure answers are in the language associated with '${values.topic}'.
  3. Do not repeat any questions; just provide the answers.
  
  Reflection on Lesson Plan
  • Summarize the key points taught in the lesson.
  • Provide a few bullet points on the reflection of the lesson plan and what has been taught.
  • Ensure the reflection is in the language associated with '${values.topic}'.
  
  Multimedia References
  • Provide a maximum of 2 age-appropriate multimedia references (websites, videos, and audio resources) related to the topic '${values.topic}'.
  • Ensure all references are in the language associated with '${values.topic}' and suitable for the target age group.
  • Verify the functionality of each link to ensure it leads to active content.
  • Exclude any links that result in a "404 Not Found" error
  
  Formatting Guidelines
  • Main Headers: Use # .
  • Subheaders: Use ## .
  • Bold: Use **bold** for all headings and subheadings.
  • Lists: Use bullet points for lists and numbered lists where appropriate.
  • Spacing: Ensure clear spacing between sections for readability.
  • Notes: Do not add any notes or topic introductions.
  
  \n\n\n\n\n`;
      console.log(activityQuery);
      return activityQuery;
    }
    try {
      const firstQuery = await generateFirstQuery(values);
      const Firstresponse = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: firstQuery,
            },
          ],
          max_tokens: 2000,
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );
      console.log("Firstresponse>>>>>>>>>>>>>>>>>>>>>>>>",Firstresponse.data.choices[0].message.content);
      const FirstresponseData = Firstresponse.data.choices[0].message.content;

      let combinedResponses = "";
      let combinedResponsesSet = new Set();
      const activityQueries = [];
      const activityResponses = [];

      for (let i = 0; i < selectedActivities.length; i++) {
        const activity = selectedActivities[i];
        const isLast = i === selectedActivities.length - 1;
        const activityQuery = await generateActivityQuery(
          activity,
          values,
          FirstresponseData,
          keywordsString,
          lessonAimsString,
          isLast,
          i
        );
        activityQueries.push(activityQuery);
      }

      for (let i = 0; i < activityQueries.length; i++) {
        const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
          {
            messages: [
              {
                role: "system",
                content: activityQueries[i],
              },
            ],
            max_tokens: 3000,
            temperature: 0.5,
            frequency_penalty: 0,
            presence_penalty: 2.0,
            stop: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "7327443ae85e44419192e4fb0544d702",
            },
          }
        );
        console.log("response>>>>>>>>>>>>>>>>>>>>>>>>",response.data.choices[0].message.content);
        activityResponses.push(response.data.choices[0].message.content);
        combinedResponsesSet.add(response.data.choices[0].message.content);
      }

      combinedResponses = [...combinedResponsesSet].join("<br/><br/>");

      const LastQuery = await generateLastQuery(values, combinedResponses);
      const Lastresponse = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: LastQuery,
            },
          ],
          max_tokens: 3000,
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );
      console.log("Lastresponse>>>>>>>>>>>>>>>>>>>>>>>>",Lastresponse.data.choices[0].message.content);
      const LastresponseData = Lastresponse.data.choices[0].message.content;

      // Combine all responses with the LastresponseData at the end
      const allText = FirstresponseData + "\n\n\n\n" + combinedResponses + "\n\n\n\n" + LastresponseData;

      // Await the formatChatGPTResponse function here
      const botReply = await formatChatGPTResponse(allText);
      console.log('allText: ', allText);
      console.log('botReply: ', botReply);

      //save to backend
      await leasonSaveToBackend(botReply, Firstresponse.data.choices[0].message.content, values)
      
      
      setLessonPlanResponse(botReply);
      setPdfData(allText);
      handleNext();
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
    } finally {
      setisLoading(false);
    }
  };

  // FUNCTION to convert newLine lags to break tags
  const validateLink = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        // Check for common 404 messages in the response data
        const invalidMessages = [
          "The requested URL was not found on this server.",
          "404 Not Found",
          "Page not found",
          "We couldn't find the page you were looking for",
        ];
        for (const message of invalidMessages) {
          if (response.data.includes(message)) {
            return false;
          }
        }
        return true;
      }
    } catch (error) {
      // Handle errors such as network issues, server errors, etc.
      return false;
    }
  };


  const formatChatGPTResponse = (response) => {
    // Preserve the original language of the response
    let formattedResponse = response;
    // Replace Markdown-style headers with HTML headers
    formattedResponse = formattedResponse.replace(/^## (.*?)$/gm, "<h3>$1</h3>");
    formattedResponse = formattedResponse.replace(/^# (.*?)$/gm, "<h2>$1</h2>");
    formattedResponse = formattedResponse.replace(/^### (.*?)$/gm, "<h2>$1</h2>");
    // Replace lists (lines starting with '-') to list items
    formattedResponse = formattedResponse.replace(/^- (.*?)$/gm, "<li>$1</li>");
    // Replace newLine tags with <br> for line breaks
    formattedResponse = formattedResponse.replace(/\n/g, "<br>");
    // Replace https links with clickable <a> tags
    formattedResponse = formattedResponse.replace(/(https?:\/\/\S+)/gi, '<a href="$1" target="_blank">$1</a>');
    // Remove any remaining HTML tags and stars (*)
    // formattedResponse = formattedResponse.replace(/<\/?[^>]+(>|$)/g, "");
    formattedResponse = formattedResponse.replace(/\*/g, "");
    // formattedResponse = `<div lang="${language}">${formattedResponse}</div>`;
    return formattedResponse;
  };


  //................................Correctly working from line 1001 to 1114 with bullets & Numbers till 17 jul.................................

  const parseAndSplitContent = (content, maxLength = 500) => {
    const sections = content.split(/(?=<h[1-3]>)/).map(section => section.trim()).filter(section => section.length > 0);
    const splitSections = [];

    sections.forEach(section => {
      if (section.length > maxLength) {
        const parts = section.match(new RegExp(`.{1,${maxLength}}(\\s|\\.|,|;|\\?)`, 'g'));
        splitSections.push(...parts);
      } else {
        splitSections.push(section);
      }
    });
    return splitSections;
  };

  const convertHtmlToRichText = (html) => {
    const richText = [];
    const div = document.createElement("div");
    div.innerHTML = html;

    div.childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        const text = node.innerText;

        switch (tagName) {
          case "h1":
            richText.push({ text, options: { fontSize: 32, bold: true } });
            break;
          case "h2":
            richText.push({ text, options: { fontSize: 28, bold: true } });
            break;
          case "h3":
            richText.push({ text, options: { fontSize: 24, bold: true } });
            break;
          case "li":
            richText.push({ text, options: { bullet: true, fontSize: 18 } });
            break;
          case "ol":
            // Handle ordered lists (numbered items)
            node.childNodes.forEach((itemNode, index) => {
              if (itemNode.nodeType === Node.ELEMENT_NODE && itemNode.tagName.toLowerCase() === "li") {
                richText.push({ text: `${index + 1}. ${itemNode.innerText}`, options: { fontSize: 18 } });
              }
            });
            break;
          case "br":
            richText.push({ text: "\n", options: { fontSize: 18 } });
            break;
          default:
            richText.push({ text, options: { fontSize: 18 } });
            break;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent.trim();
        if (textContent.length > 0) {
          richText.push({ text: textContent, options: { fontSize: 18 } });
        }
      }
    });
    return richText;
  };

  const generatePPTX = () => {
    const pptx = new PptxGenJS();
    const slideWidthInches = 10;
    const slideHeightInches = 7.5;
    const maxFontSize = 24;
    const minFontSize = 10;
    const maxLinesPerSlide = 15;

    // Set slide dimensions and master slide to ensure no default margins
    pptx.defineSlideMaster({
      title: "Master Slide",
      width: slideWidthInches,
      height: slideHeightInches,
      margin: { top: 0, left: 0, right: 0, bottom: 0 } // Ensure no default margins
    });

    let slide = pptx.addSlide();
    let currentText = '';
    let lineCount = 0;

    const finalizeSlide = () => {
      if (currentText.trim().length > 0) {
        slide.addText(currentText, {
          x: 0, // Start at the very left of the slide
          y: 0, // Start at the very top of the slide
          w: slideWidthInches, // Full width of the slide
          h: slideHeightInches, // Full height of the slide
          align: "left",
          valign: 'top', // Vertical alignment at the top
          fontSize: Math.max(minFontSize, maxFontSize - (lineCount * 1.5)),
          margin: { left: 0, top: 0 } // Ensure no extra margins
        });
        currentText = '';
        lineCount = 0;
        slide = pptx.addSlide(); // Add a new slide
      }
    };

    const sections = parseAndSplitContent(lessonPlanResponse);

    sections.forEach((section, index) => {
      const richText = convertHtmlToRichText(section);

      richText.forEach(textObj => {
        const text = textObj.text;
        const lines = text.split('\n');
        const options = textObj.options || {};

        lines.forEach(line => {
          const trimmedLine = line.trim();
          if (trimmedLine.length === 0 || trimmedLine === '---') {
            return; // Skip empty lines or "---"
          }

          if (trimmedLine.startsWith("#") && currentText.trim().length > 0) {
            // Start a new slide for each heading if the current slide has content
            finalizeSlide();
          }

          if (lineCount >= maxLinesPerSlide || currentText.length + line.length > 1000) {
            finalizeSlide();
          }

          // Add bullet or number based on options
          if (options.bullet) {
            currentText += `• ${trimmedLine}\n`;
          } else {
            currentText += trimmedLine + '\n';
          }
          lineCount++;
        });
      });
    });
    finalizeSlide(); // Ensure the last slide is finalized
    pptx.writeFile({ fileName: "CourseLessonPlan.pptx" });
  };

  // FUNCTION to copy text to clipBoard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(pdfData)
      .then(() => {
        setIsCopied(true);
      }).catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  // FUNCTION Goto next step in Stepper
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // FUNCTION Set Progress
  const setProgressValue = () => {
    setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
  };

  const { t, i18n } = useTranslation();
  const correctPlan = async () => {
    setModalLoading(true);
    const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are about to make changes to the lesson plan. Here is the original lesson plan: ${lessonPlanResponse}`,
          },
          {
            role: "user",
            content: `${correction} \nDon't alter the structure of lesson plan and make changes in that existing structure. Please write the corrected lesson plan after the word *****.`,
          },
        ],
        // max_tokens: 650,
        temperature: 0.5,
        frequency_penalty: 0,
        presence_penalty: 2.0,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    setLessonPlanResponse(response.data.choices[0].message.content.split("*****")[1]);
    setModalLoading(false);
    setCorrection("");
    setOpenModal(false);
  };




  return (
    <>
      <Box className={classes.Container}>
        <div>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="row">
          <Button style={{ margin: '10px' }} type='button' variant="contained" color="primary" onClick={()=> {history.goBack()}}><span className="MuiButton-label">{t("Back") }</span></Button>
        </Box>
          {
            content==="" &&
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step
                    key={label}
                    {...stepProps}
                    onClick={() => {
                      setActiveStep(0);
                      setLessonPlanResponse("");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          }
        </div>
        <div className={classes.formikWrapper}>
          {lessonPlanResponse == "" ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form style={{ fontWeight: "bold" }}>
                  <Grid container spacing={5}>
                    {/* <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="topic">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("topic")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      {/* university */}
                      <Field name="subtopic">
                        {({ field, meta }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t("subtopic")}</InputLabel>
                            <Select
                              className={classes.select}
                              {...field}
                              label={t("subtopic")}
                              error={meta.touched && meta.error !== undefined}
                              helperText={meta.touched && meta.error}
                            >
                              <MenuItem value = ""><em>None</em></MenuItem>
                              {courseDetail?.detailedCourseBreakdown?.weeks[weekId].hours[topicId].subtopics.map((val, index) => (
                                  <MenuItem key={index} value={val.subtopic}>{val.subtopic}</MenuItem>
                                ))
                              }
                              {console.log("subtopicshere>>>>>>>>>>>>",courseDetail?.detailedCourseBreakdown?.weeks[weekId].hours[topicId].subtopics)}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="activity">
                        {({ field, meta, form }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>{t("activity_label")}</InputLabel>
                            <Select
                              {...field}
                              multiple
                              label={t("activity_label")}
                              error={meta.touched && meta.error !== undefined}
                              renderValue={(selected) => (
                                <div>
                                  {selected.map((value) => (
                                    <Chip key={value} label={t(value)} className={classes.chip}/>
                                  ))}
                                </div>
                              )}
                            >
                              {activities.map((activity) => (
                                <MenuItem key={activity} value={activity}>
                                  <Button
                                    className={classes.button}
                                    onClick={() => {
                                      const newSelected = field.value.includes(activity)
                                        ? field.value.filter((val) => val !== activity)
                                        : [...field.value, activity];
                                      form.setFieldValue(field.name,newSelected);
                                    }}
                                    variant={field.value.includes(activity) ? "contained" : "outlined"}
                                  >
                                    {t(activity)}
                                  </Button>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  {/* Submit Button */}
                  <div style={{ width: "80%", marginTop: 10 }}>
                    {isLoading ? (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress variant="determinate" value={progress}/>
                        </Box>
                        <br />
                        <Button color="secondary" variant="contained" onClick={handleCancel}>{t("cancel")}</Button>
                      </>
                    ) : (
                      <div className={classes.ButtonGroup}>
                        <Button type="button" variant="contained" color="primary" onClick={() => history.goBack()}>{t("cancel")}</Button>
                        <Button type="submit" variant="contained" color="primary">{t("generate_plan")}</Button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className={classes.WrapperResponse}>
              <p
                style={{ textAlign: "left !important", fontFamily: "Calibri, Arial, sans-serif"}}
                id="content"
                dangerouslySetInnerHTML={{ __html: lessonPlanResponse }}
              ></p>
              <div className={classes.ButtonGroup}>
                <Button color="primary" variant="contained" onClick={() => setOpenModal(true)} > {t("correct")}</Button>

                <Button color="primary" variant="contained" onClick={copyToClipboard}>
                  {isCopied ? "Copied" : <ContentCopyIcon />}
                </Button>
                {/* <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  {t("edit_lesson_plan")}
                </Button> */}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={generatePPTX}
                >
                  {t("generate_pptx")}
                </Button>

                {
                  content==="" &&
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setActiveStep(0);
                      setLessonPlanResponse("");
                    }}
                  >
                    {t("generate_new_lesson_plan")}
                  </Button>
                }
              </div>
            </div>
          )}
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyles}>
            {modalLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t("correct_lesson_plan")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label={t("description")}
                  variant="outlined"
                  className={classes.input}
                  style={{ marginTop: 15 }}
                  value={correction}
                  onChange={(e) => {
                    if (e.target.value.trim()) {
                      setCorrection(e.target.value);
                    }
                  }}
                />
                <div className="d-flex justify-content-end mt-4 align-items-center">
                  <Button color="primary" variant="contained"  onClick={() => setOpenModal(false)}>
                    {t("cancel")}
                  </Button>
                  <Button color="primary" variant="contained" style={{ marginLeft: 5 }}
                    onClick={() => {
                      if (correction) {
                        correctPlan();
                      }
                    }}
                  >
                    {t("submit")}
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </Box>


    </>
  )
}

export default CourseLesson
